import React, { useEffect } from 'react';
import contact from '../Assets/images/contact.jpg'
import { TfiEmail } from "react-icons/tfi";
import { CiLocationOn } from "react-icons/ci";
import { PiPhoneCall } from "react-icons/pi";
import { PiUserLight } from "react-icons/pi";
import { GrTwitter } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const ContactPage = () => {
  useEffect(() => {
    document.title = "IBER | Contact Us";
  }, []);

  const cards = [
    {
      component: <CiLocationOn size={30} className='text-[#f79d2c]' />,
      name: "Our Location",
      description: "Bhubaneswar,Odisha"
    },
    {
      component: <TfiEmail size={30} className='text-[#f79d2c]' />,
      name: "Email Us",
      description: "info@iber.com"
    },
    {
      component: <PiPhoneCall size={30} className='text-[#f79d2c]' />,
      name: "Call Us",
      description: "+91 9777989115 "
    }
  ];

  return (
    <React.Fragment>
      <div className="relative">
        <img
          className='h-80 w-full mb-10  object-cover '
          src={contact}
          alt="contact-pic" />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <h1 className="text-white text-5xl font-bold pulse-HeaderImg">
            Contact Us
          </h1>
        </div>
      </div>


      <div className='grid md:grid-cols-3 gap-4 mb-10 mx-10'>
        {cards.map((v, i) => (
          <div key={i} className='flex flex-col  items-center text-center hover:border h-72 shadow-lg rounded-md gap-3 p-3 hover:border-primaryColor transition ease-in-out hover:duration-300'>

            <div className='rounded-full shadow-lg p-5 text-center mt-8 hover:scale-110 hover:-translate-y-3 duration-300'>{v.component}</div>

            <div className='text-[#233d63] font-bold text-2xl tracking-wide'>{v.name}</div>
            <span className='text-gray-500'>{v.description}</span>
          </div>
        ))}
      </div>

      <div className='grid md:grid-cols-2 p-4 mb-10'>
        <div className='flex flex-col gap-3 mx-auto p-4 justify-center items-center'>
          <h1 className='text-3xl font-bold'>We'd love to hear from you</h1>
          <span className='text-start font-semibold text-lg text-[#7f8897] inline-block'>Lorem ipsum is simply free text dolor sit amett quie adipiscing elit. When an unknown printer took a galley. quiaies lipsum dolor sit atur adip scing</span>
          <div className='flex gap-4'>
            <div className='shadow-lg p-3 rounded-full hover:-translate-y-2 duration-300 hover:scale-110 '><GrTwitter /></div>
            <div className='shadow-lg p-3 rounded-full hover:-translate-y-2 duration-300 hover:scale-110 '><FaFacebookF /></div>
            <div className='shadow-lg p-3 rounded-full hover:-translate-y-2 hover:scale-110 duration-300'><RiInstagramFill /></div>
          </div>
        </div>

        <div className='shadow-lg p-4 rounded-md'>
          <div className='relative w-full mb-3 px-3'>
            <div className="text-[#132c4a] block text-md font-bold mb-2 ">Your Name</div>
            <input
              type="text"
              id="name"
              autoComplete='off'
              className={
                0
                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                  : "text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
              }
              placeholder="Your Name"
            />
            <span className='absolute top-11 left-8'><PiUserLight /></span>
          </div>
          <div className='relative w-full mb-3 px-3'>
            <div className="text-[#132c4a] block text-md font-bold mb-2 ">Email Address</div>
            <input
              type="email"
              id="email"
              autoComplete='off'
              className={
                0
                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                  : "text-[#132c4a] border-0 pl-14 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
              }
              placeholder="Enter email address"
            />
            <span className='absolute top-11 left-8'><TfiEmail /></span>
          </div>
          <div className='relative w-full mb-3 px-3'>
            <div className="text-[#132c4a] block text-md font-bold mb-2 "> Message</div>
            <textarea
              type="text"
              id="message"
              className={
                0
                  ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                  : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 font-semibold"
              }
              placeholder="Write Message"
            />
          </div>
          <div className='relative w-full mb-3 px-3'>
            <button className='text-white bg-[#dc3545] hover:bg-[#c14751] p-2 font-semibold rounded-md shadow-md'>Send Message</button>
          </div>

        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactPage;
