import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ImScissors, ImMenu } from 'react-icons/im';
import { BsStars } from "react-icons/bs";
import { IoMdClose } from 'react-icons/io';
import { CgMenuLeft } from "react-icons/cg";
import logo from "../Assets/images/newLogo.png";
import ContactFormModal from './ContactFormModal';

const Navbar = () => {
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [open, setOpen] = useState(false);

    const isActive = (pathname) => {
        return location.pathname === pathname;
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <React.Fragment>
            <ContactFormModal
                open={open}
                setOpen={setOpen}
            />
            <nav className="flex items-center justify-between bg-white shadow-lg text-gray-800 py-4 px-10">
                <div className='flex justify-start items-center'>
                    <img src={logo} alt="IBER" height={80} width={80} />
                </div>

                <div className="md:hidden"
                    onClick={toggleSidebar}>
                    <CgMenuLeft size={24} />
                </div>

                {/* Sidebar for Mobile */}
                {isSidebarOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 md:hidden">
                        <div className="fixed top-0 left-0 w-64 bg-white h-full shadow-lg">
                            <div className="flex justify-end p-4">
                                <IoMdClose size={30} onClick={toggleSidebar} />
                            </div>
                            <div className="flex  flex-col items-start px-4">
                                {['/home', '/services', '/about', '/contact', 'https://business.iber.co.in/login'].map((path, index) => (
                                    <Link to={path} key={index}>
                                        <div className={`my-2 px-3 py-2 font-semibold ${isActive(path) ? 'text-primaryColor' : 'text-primaryTextColor'} hover:text-gray-400`} onClick={toggleSidebar}>
                                            {path === '/home' && 'Home'}
                                            {path === '/services' && 'Services'}
                                            {path === '/about' && 'About Us'}
                                            {path === '/contact' && 'Contact Us'}
                                            {path === 'https://business.iber.co.in/login' && 'Login'}
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex-grow hidden md:flex justify-center font-semibold">
                    {['/home', '/services', '/about', '/contact'].map((path, index) => (
                        <Link to={path} key={index}>
                            <div className={`mx-4 px-3 tracking-wider py-1 ${isActive(path) ? 'text-primaryColor' : ' text-primaryTextColor hover:text-gray-400'} transition ease-in-out hover:duration-300 transform hover:scale-110`}>
                                {path === '/home' && 'Home'}
                                {path === '/services' && 'Services'}
                                {path === '/about' && 'About Us'}
                                {path === '/contact' && 'Contact Us'}
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="hidden md:flex md: gap-4">
                    <button
                        onClick={() => window.open("https://business.iber.co.in/login", "_self")}
                        className='cursor-pointer tracking-wider px-4 py-2 flex justify-center items-center text-lg text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg transform '
                        type="button">
                        Login
                    </button>
                    <button
                        onClick={() => setOpen(true)}
                        className="cursor-pointer tracking-wider px-4 py-2 flex justify-center items-center text-sm lg:text-lg text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg transform flip-on-hover">
                        <div className="flip-on-hover-text">
                            <BsStars
                                color='#fff'
                                size={22}
                            />&nbsp; Book Free demo
                        </div>
                    </button>
                </div>
            </nav>
        </React.Fragment >
    );
}

export default Navbar;
