import React, { useEffect } from 'react';
import Header from '../components/Header';
import BusinessNeeds from '../components/BusinessNeeds';
import ManageFeatures from '../components/ManageFeatures';
import FeaturesProof from '../components/FeaturesProof';
import Testimonials from '../components/Testimonials';
import SubscribeForm from '../components/SubscribeForm';
import Features from '../components/Features';

const Home = () => {
  useEffect(() => {
    document.title = "IBER | Home";
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Features />
      <BusinessNeeds />
      <ManageFeatures />
      <FeaturesProof/>
      <Testimonials />
      <SubscribeForm />
    </React.Fragment>
  );
};

export default Home;
