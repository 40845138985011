import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import testimonial1 from '../Assets/images/testimonial1.avif'
import testimonial2 from '../Assets/images/testimonial2.webp'
import testimonial3 from '../Assets/images/testimonial3.avif'
import testimonial4 from '../Assets/images/testimonial4.avif'
import testimonial5 from '../Assets/images/testimonial5.avif'
import testimonial6 from '../Assets/images/testimonial6.avif'

const testimonials = [
    {
        quote: "IBER POS software has transformed our business operations. The all-in-one solution has streamlined everything from inventory management to invoicing. The ease of use and real-time analytics have empowered our team to work more efficiently.",
        name: "Priya Verma",
        title: "Owner, Urban Boutique",
        url:testimonial1,
    },
    {
        quote: "Switching to IBER POS software was one of the best decisions we made. The secure protocols and intuitive interface made it easy for our staff to adapt quickly. The customer support is outstanding, always ready to assist with any questions.",
        name: "Rahul Mehta",
        title: "General Manager, FreshMart Groceries",
        url:testimonial6,
    },
    {
        quote: "The comprehensive features of IBER POS software have made managing our business a breeze. The integration of employee management and inventory tracking has significantly reduced our workload. The advanced security gives us complete confidence in our data's safety.",
        name: "Anita Desai",
        title: "CEO, Elegant Spa",
        url:testimonial5,
    },
    {
        quote: "IBER POS software has truly exceeded our expectations. The support we’ve received has been exceptional, and the software's user-friendly design made the transition seamless. The detailed reporting features have provided us with invaluable insights into our operations.",
        name: "Vikram Singh",
        title: "Owner, Bistro Café",
        url:testimonial3,
    },
    {
        quote: "As a small business owner, I needed a reliable and secure POS system, and IBER delivered. The software’s robust features, especially the real-time analytics, have helped us optimize our processes and grow our business. I highly recommend it to anyone in the retail industry.",
        name: "Rohit Agarwal",
        title: "Founder, Trendy Threads",
        url:testimonial4,
    },
    {
        quote: "IBER POS management software has been a game-changer for us. The integration of multiple features into one system has simplified our daily tasks. The peace of mind we get from the advanced security measures is priceless, and the customer service is always responsive and helpful.",
        name: "Shreya Patel",
        title: "Director, Fitness Hub",
        url:testimonial2,
    }
];


const Testimonials = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials?.length);
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials?.length) % testimonials?.length);
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials?.length);
    };

    return (
        <React.Fragment>
            <div className='mx-10'>
                <div className='tracking-wide text-center mt-6 text-base md:text-lg text-primaryColor'>
                    Our Testimonials
                </div>

                <div className="tracking-wide mt-2 text-center text-3xl md:text-4xl font-bold text-primaryTextColor leading-tight md:leading-snug">
                    What People Say’s about Us !
                </div>

                <div className="flex justify-center items-center mt-6">
                    <div className="flex-none flex justify-center items-center w-1/12">
                        <button 
                            onClick={handlePrevClick}
                            className="text-2xl font-bold bg-[#EBF8FF] hover:bg-primaryColor text-primaryColor hover:text-white rounded-full h-12 w-12 flex justify-center items-center transition-transform duration-300 hover:scale-110 ease-in-out hover:duration-300"
                        >
                            <FaArrowLeft size={22} />
                        </button>
                    </div>

                    <div className="flex-grow flex flex-col items-center text-center max-w-5xl">
                        <div className='w-full tracking-wide text-center mt-2 text-base md:text-lg text-[#A9A8C0]'>
                            {testimonials[currentIndex].quote}
                        </div>

                        <img 
                            src={testimonials[currentIndex].url} 
                            alt="Profile" 
                            className="w-28 h-28 object-cover rounded-full mt-2" 
                        />

                        <h2 className="text-xl font-bold mt-2">
                            {testimonials[currentIndex].name}
                        </h2>
                        <h3 className="text-md font-medium mt-1 text-[#A9A8C0]">
                            {testimonials[currentIndex].title}
                        </h3>
                    </div>

                    <div className="flex-none flex justify-center items-center w-1/12">
                        <button 
                            onClick={handleNextClick}
                            className="text-2xl font-bold bg-[#EBF8FF] hover:bg-primaryColor text-primaryColor hover:text-white rounded-full h-12 w-12 flex justify-center items-center transition-transform duration-300 hover:scale-110 ease-in-out hover:duration-300"
                        >
                            <FaArrowRight size={22} />
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonials;
