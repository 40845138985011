import React from 'react';
import threeMobilePic from '../Assets/images/threeMobilePic.webp';
import shape1 from '../Assets/images/shape.webp';
import shape2 from '../Assets/images/shape2.webp';

const SubscribeForm = () => {
    return (
        <React.Fragment>
            <div className="mt-6 bg-[#EBF8FF] px-10">
                <div className="flex flex-col md:flex-row items-center justify-between py-8">
                    <div className="md:w-1/2">
                        <h1 className="text-primaryTextColor mb-5 text-3xl md:text-5xl font-extrabold leading-tight md:leading-snug">
                            Get Our <span className='text-primaryColor animate-pulse'>IBER</span> App
                        </h1>

                        <p className="text-md md:text-lg mb-5 text-base text-[#A9A8C0]">
                            We will send you a link to open it on your phone to download the app.
                        </p>

                        <div className="flex mt-4 w-full">
                            <input
                                type="email"
                                placeholder="Email.."
                                className="w-[70%] p-3 border border-gray-300 rounded-l-md focus:outline-none focus:border-blue-500" />

                            <button
                                className="tracking-wider text-lg bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 transform text-white px-4 rounded-r-md ">
                                Subscribe
                            </button>
                        </div>
                    </div>

                    <div className="md:w-1/2 flex justify-center items-center mt-6 md:mt-0 relative">
                        <img
                            src={shape1}
                            alt="Small Image"
                            className="absolute top-1 left-0 w-12 h-12 moveSlowlyRightTop" />

                        <img src={shape2}
                            alt="Second Small Image"
                            className="absolute top-1 right-0 w-12 h-12 moveRightToLeft" />


                        <img src={threeMobilePic}
                            alt="threeMobilePic"
                            className="max-w-xs md:max-w-2xl pulse-HeaderImg" />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default SubscribeForm
