import React from "react";
import { ImScissors } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/images/newLogo.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer className="bg-white py-8 relative">
        <div className="absolute top-0 left-0 right-0 h-2 before:absolute before:left-0 before:right-0 before:bottom-0 before:h-2 before:bg-gradient-to-b from-gray-200 to-transparent"></div>

        <div className=" mx-auto px-10 ">
          <div className="flex flex-wrap justify-between ">
            <div className="w-full sm:w-1/3 mb-6">
              <div className="flex justify-start items-center">
                <img src={logo} alt="IBer" width={60} />
                {/* <div className="text-2xl font-bold ml-2">i-Ber</div> */}
              </div>

              <p className="text-[#A9A8C0] text-base tracking-wide text-justify mt-4">
                Streamline your business operations with our all-in-one POS
                management software, Designed to enhance efficiency and provide
                valuable insights, our software empowers your business to grow
                and succeed.
              </p>
            </div>

            <div className="w-full sm:w-1/3 mb-6 text-center">
              <h5 className="text-lg mb-4 font-bold text-primaryTextColor">
                Useful Links
              </h5>

              <div className="flex flex-col">
                <span
                  onClick={() => navigate("/home")}
                  className="text-[#A9A8C0] font-semibold cursor-pointer hover:text-primaryColor tracking-wider mb-2"
                >
                  Home
                </span>

                <span
                  onClick={() => navigate("/services")}
                  className="text-[#A9A8C0] font-semibold cursor-pointer hover:text-primaryColor tracking-wider mb-2"
                >
                  Services
                </span>

                <span
                  onClick={() => navigate("/about")}
                  className="text-[#A9A8C0] font-semibold cursor-pointer hover:text-primaryColor tracking-wider mb-2"
                >
                  About US
                </span>

                <span
                  onClick={() => navigate("/contact")}
                  className="text-[#A9A8C0] font-semibold cursor-pointer hover:text-primaryColor tracking-wider mb-2"
                >
                  Contact US
                </span>
              </div>
            </div>

            <div className="w-full sm:w-1/3 mb-6 text-center">
              <h5 className="text-lg mb-4 font-bold">Get in Touch</h5>

              <p className="text-gray-400 text-sm">
                Address:Bhubaneswar,Odisha
                <br />
                Phone: +91 9777989115
                <br />
                Email: info@iber.com
              </p>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="tracking-wide text-primaryColor">
              © Copyright 2024 All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
