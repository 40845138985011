import React from 'react';
import employee from '../Assets/images/employee.webp';
import appointment from '../Assets/images/appointment.webp';
import inventory from '../Assets/images/inventory.webp';
import laser from '../Assets/images/laser.webp';
import analytics from '../Assets/images/analytics.webp';
import campaign from '../Assets/images/campaign.webp';
import invoice from "../Assets/images/Invoice.png";
import Expenditure from "../Assets/images/Expenditure.png";
import Discounts from "../Assets/images/Discounts.png";
import Products from "../Assets/images/Products.png";
import Membership from "../Assets/images/Membership.png";
import Clients from "../Assets/images/clients.png";

const ManageFeatures = () => {
    const cards = [
        {
            imageUrl: employee,
            name: "Employee Management",
            description: "Create schedules, handle payroll & manage your employees stress free."
        },
        {
            imageUrl: appointment,
            name: "Appointment",
            description: "Effortless client booking software that functions 24X7. Now, modify, cancel and reschedule bookings super easily."
        },
        {
            imageUrl: inventory,
            name: "Inventory",
            description: "tBudgeting, restocking & tracking of inventory now made easy.his"
        },
        {
            imageUrl: laser,
            name: "Laser",
            description: "Track daily & weekly expenses seamlessly."
        },
        {
            imageUrl: analytics,
            name: "Analytics",
            description: "User friendly interface to monitor your revenue & take better business decisions."
        },
        {
            imageUrl: campaign,
            name: "Campaign",
            description: "Run exclusive offers and discounts for clients to increase client loyalty."
        },
        {
            imageUrl: invoice,
            name: "Invoice",
            description: "Track daily & weekly expenses seamlessly.",
          },
          {
            imageUrl: Expenditure,
            name: "Expenditure",
            description:
              "User friendly interface to monitor your revenue & take better business decisions.",
          },
          {
            imageUrl: Discounts,
            name: "Discounts",
            description:
              "Run exclusive offers and discounts for clients to increase client loyalty.",
          },
          {
            imageUrl: Products,
            name: "Products",
            description: "Track daily & weekly expenses seamlessly.",
          },
          {
            imageUrl: Membership,
            name: "Membership",
            description:
              "User friendly interface to monitor your revenue & take better business decisions.",
          },
          {
            imageUrl: Clients,
            name: "Clients",
            description:
              "Run exclusive offers and discounts for clients to increase client loyalty.",
          },
    ];

    return (
        <React.Fragment>
            <div className="tracking-wide mt-6 text-center text-3xl md:text-4xl font-bold text-primaryTextColor leading-tight md:leading-snug">
                Manage, Experience & Grow with<br /> <span className='text-primaryColor animate-pulse'>IBER</span> Salon Software!
            </div>

            <div className=" mx-10 mt-4">
                <div className="grid md:grid-cols-3 gap-4">
                    {cards.map((card, index) => (
                        <div key={index} className="border border-gray-300 hover:border-primaryColor p-4 rounded-lg transition ease-in-out hover:duration-300">
                            <img src={card.imageUrl}
                                alt={card.name}
                                className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-md"
                                style={{ animation: 'spin-and-pause 4s linear infinite' }}
                                />

                            <h3 className="text-primaryTextColor font-bold text-xl my-2 tracking-wide">
                                {card.name}
                            </h3>

                            <p className="text-[#A9A8C0] text-base tracking-wide">
                                {card.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ManageFeatures
