import React, { useEffect } from "react";
import serviceImage from "../Assets/images/Frame 427321151 (1).png";
import employee from "../Assets/images/employee.webp";
import appointment from "../Assets/images/appointment.webp";
import inventory from "../Assets/images/inventory.webp";
import laser from "../Assets/images/laser.webp";
import analytics from "../Assets/images/analytics.webp";
import branch from "../Assets/images/Branch.png";
import invoice from "../Assets/images/Invoice.png";
import Expenditure from "../Assets/images/Expenditure.png";
import Discounts from "../Assets/images/Discounts.png";
import Products from "../Assets/images/Products.png";
import Membership from "../Assets/images/Membership.png";
import Clients from "../Assets/images/clients.png";

const Services = () => {
  useEffect(() => {
    document.title = "IBER | Services";
  }, []);

  const cards = [
    {
      imageUrl: employee,
      name: "Employee Management",
      description:
        "Create schedules, handle payroll & manage your employees stress free.",
    },
    {
      imageUrl: appointment,
      name: "Appointment",
      description:
        "Effortless client booking software that functions 24X7. Now, modify, cancel and reschedule bookings super easily.",
    },
    {
      imageUrl: inventory,
      name: "Inventory",
      description:
        "tBudgeting, restocking & tracking of inventory now made easy.his",
    },
    {
      imageUrl: laser,
      name: "Ledger",
      description: "Track daily & weekly expenses seamlessly.",
    },
    {
      imageUrl: analytics,
      name: "Analytics",
      description:
        "User friendly interface to monitor your revenue & take better business decisions.",
    },
    {
      imageUrl: branch,
      name: "Branches",
      description:
        "Run exclusive offers and discounts for clients to increase client loyalty.",
    },
    {
      imageUrl: invoice,
      name: "Invoice",
      description: "Track daily & weekly expenses seamlessly.",
    },
    {
      imageUrl: Expenditure,
      name: "Expenditure",
      description:
        "User friendly interface to monitor your revenue & take better business decisions.",
    },
    {
      imageUrl: Discounts,
      name: "Discounts",
      description:
        "Run exclusive offers and discounts for clients to increase client loyalty.",
    },
    {
      imageUrl: Products,
      name: "Products",
      description: "Track daily & weekly expenses seamlessly.",
    },
    {
      imageUrl: Membership,
      name: "Membership",
      description:
        "User friendly interface to monitor your revenue & take better business decisions.",
    },
    {
      imageUrl: Clients,
      name: "Clients",
      description:
        "Run exclusive offers and discounts for clients to increase client loyalty.",
    },
  ];

  return (
    <React.Fragment>
      <div className="relative">
        <img
          className="h-auto w-full mb-10  object-cover "
          src={serviceImage}
          alt="salon-pic"
        />
        <div
          className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <h1 className="text-white text-5xl font-bold pulse-HeaderImg">
            Services
          </h1>
        </div>
      </div>

      <div className="font-bold tracking widest text-center flex-col flex gap-2">
        <div className="text-4xl">Spa & Salon Management Software  </div>
        <div className="text-xl" >We are Dealing With All This Features</div>
      </div>
      <div className=" mx-10 my-10">
        <div className="grid md:grid-cols-3 gap-4">
          {cards.map((card, index) => (
            <div
              key={index}
              className="border border-gray-300 hover:border-primaryColor p-4 rounded-lg transition ease-in-out hover:duration-300"
            >
              <img
                src={card.imageUrl}
                alt={card.name}
                className="w-24 h-24 md:w-32 md:h-32 object-cover rounded-md"
                style={{ animation: "spin-and-pause 4s linear infinite" }}
              />

              <h3 className="text-primaryTextColor font-bold text-xl my-2 tracking-wide">
                {card.name}
              </h3>

              <p className="text-[#A9A8C0] text-base tracking-wide">
                {card.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Services;
